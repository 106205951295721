@import "variables";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

body#had {
  #main {
    #related_resources {
      margin: 2.5em auto;
      .container {
        .top {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          a.prev {
            margin-right: 30px;
          }
          a {
            &:hover {
              svg {
                fill: #0a7198;
                path {
                  fill: #0a7198;
                }
              }
            }
          }
        }
        .glide__slides {
          white-space: normal;
        }
        .glide-controls {
          a {
            position: absolute;
            top: 55%;
            right: -45px;
            transform: translate(-50%, -50%);
            z-index: 3;
            &:hover {
              opacity: 0.75;
            }
            &.prev {
              left: 15px;
              right: auto;
            }
            &.glide__arrow--disabled {
              display: none;
            }
            @media only screen and (min-width: 768px) {
              right: -45px;
              &.prev {
                left: 15px;
              }
            }
            @media only screen and (min-width: 1200px) {
              right: 0;
              &.prev {
                left: 50px;
              }
            }
          }
        }
        .list {
          .resource {
            margin-bottom: 2.5em;
            &:last-child {
              margin-bottom: 0;
            }
            a.img {
              height: 250px;
              display: block;
              border-top-left-radius: 12px;
              border-top-right-radius: 12px;
              border-bottom-left-radius: 12px;
              border-bottom-right-radius: 12px;
              background-color: $backgroundgray;
              overflow: hidden;
              text-align: center;
              position: relative;
              p {
                margin: 0;
                // width: 100%;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                text-align: center;
                font-weight: 700;
                font-size: 1em;
                padding: 0.62em;
              }
              img {
                width: 100%;
                display: block;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
              }
              &:not(.icon) {
                img {
                  object-fit: cover;
                  width: 100%;
                  height: 212px;
                  display: block;
                  border-top-left-radius: 0;
                  border-top-right-radius: 0;
                }
              }
              &.icon {
                img {
                  position: absolute;
                  top: calc(50% + 20px);
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 40%;
                }
              }
            }
            .info {
              margin-top: 1.5em;
              * {
                white-space: normal;
              }
              .top {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                p {
                  margin: 0;
                  font-size: 1em;
                  &.topic {
                    font-weight: 700;
                  }
                  &.date {
                    font-weight: 400;
                  }
                }
              }
              h4 {
                font-weight: 700;
                font-size: 1.27em;
                margin: 0.5em 0 1em;
                a {
                  color: $textcolor;
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
              p {
                margin: 0 0 1em;
              }
              a {
                &.link {
                  color: #0a7198;
                  font-style: italic;
                  margin: 0;
                  font-size: 1.12em;
                  svg,
                  img {
                    margin-left: 5px;
                  }
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
            &[data-type="case-study"] {
              a.img {
                p {
                  background-color: $darkblue;
                  color: white;
                }
              }
            }
            &[data-type="deal"] {
              a.img {
                p {
                  background-color: $darkblue;
                  color: white;
                }
              }
            }
            &[data-type="blog"] {
              a.img {
                p {
                  background-color: $berry;
                  color: white;
                }
              }
            }
            &[data-type="podcast"] {
              a.img {
                p {
                  background-color: $lightblue;
                }
              }
            }
            &[data-type="video"] {
              a.img {
                p {
                  background-color: $purple;
                  color: white;
                }
              }
            }
            &[data-type="audio"] {
              a.img {
                p {
                  background-color: $purple;
                  color: white;
                }
              }
            }
            &[data-type="article"] {
              a.img {
                p {
                  background-color: $green;
                  color: white;
                }
              }
            }
            &[data-type="whitepaper"] {
              a.img {
                p {
                  background-color: $green;
                  color: white;
                }
              }
            }
            &[data-type="factsheet"],
            &[data-type="brochure"] {
              a.img {
                p {
                  background-color: $green;
                  color: white;
                }
              }
            }
            &[data-type="news"] {
              a.img {
                p {
                  background-color: $gold;
                  color: white;
                }
              }
            }
            &[data-type="event"] {
              a.img {
                p {
                  background-color: $gold;
                  color: white;
                }
              }
            }
            &[data-type="e-book"]{
              a.img {
                p {
                  background-color: $graphite;
                  color: white;
                }
              }
            }
            &[data-type="ebook"] {
              a.img {
                p {
                  background-color: #c7c6c6;
                }
              }
            }
          }
        }
      }
    }
  }
}
