@import "variables";

body#had {
  footer#footer {
    margin-top: 5em;
    .container {
      @include container();
    }
    .contact {
      background-color: rgb(58, 66, 72);
      width: 100%;
      position: relative;
      overflow: hidden;
      &:before {
        content: "";
        position: absolute;
        top: -30px;
        right: 0;
        transform: translate(-10%, 0px);
        width: 100%;
        height: calc(100% + 40px);
        opacity: 0.05;
        background-size: 100%;
        background-position: center left;
        background-repeat: no-repeat;
        background-image: url($herochevrondark);
        z-index: 2;
        overflow: hidden;
        @include transition(transform, 1s, ease-out, 0s);
        @media screen and (min-width: 768px) {
          width: 2200px;
        }
      }
      &.on {
        &:before {
          transform: translate(-10%, 0px);
        }
      }
      &:after {
        @include white_box_triangle();
      }
      .container {
        padding: 8em 0 3em;
        // height: 100%;
        z-index: 2;
        .col {
          margin-bottom: 2.5em;
        }
        h2,
        h3,
        ol,
        li,
        p {
          color: white;
        }
        .col {
          *:first-child {
            margin-top: 0;
          }
          *:last-child {
            margin-bottom: 0;
          }
          form {
            input[type="radio"] {
              + label {
                color: white;
              }
            }
          }
        }
      }
    }
    .row {
      &.top {
        margin-top: 60px;
      }
      .container {
        text-align: center;
      }
      a.logo {
        display: block;
        margin-bottom: 3.3em;
        img {
          width: 136px;
        }
      }
      #footer_nav {
        margin-bottom: 3em;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          font-size: 20px;
          li {
            margin-bottom: 1.3em;
            a {
              font-weight: bold;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      #footer_social {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        p {
          font-size: 16px;
          margin: 0 12px 0 0;
        }
        .ccm-block-social-links {
          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            font-size: 25px;
            li {
              margin: 0;
              margin-right: 15px;
              a {
                color: $textcolor;
                @include transition(color, 0.15s, ease-out, 0s);
                &:hover {
                  color: $berry;
                }
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
      &.copyright {
        margin-top: 2em;
        .container {
          border-top: 1px solid rgba(61, 70, 76, 0.5);
          padding-top: 15px;
          padding-bottom: 10px;
          p {
            font-size: 12px;
          }
          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            font-size: 15px;
            li {
              display: inline-block;
              &:after {
                content: "|";
                margin: 0 7px;
              }
              a {
                font-style: italic;
                &:hover {
                  text-decoration: underline;
                }
              }
              &:last-child {
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    @media only screen and (min-width: 768px) {
      .contact {
        .container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-start;
          padding: 9.5em 0 7.7em;
          .col {
            width: 46.4%;
            margin-right: 3.6%;
            margin-bottom: 0;
          }
        }
      }
    }
    @media only screen and (min-width: 1024px) {
      .row {
        &.top {
          margin-top: 45px;
        }
        .container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
        a.logo {
          margin-bottom: 0;
        }
        #footer_nav {
          margin-bottom: 0;
          ul {
            li {
              display: inline-block;
              margin-right: 25px;
              margin-bottom: 0;
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
    @media only screen and (min-width: 1200px) {
      .row {
        a.logo {
          img {
            width: 187px;
          }
        }
        #footer_nav {
          ul {
            li {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}
