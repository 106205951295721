@import "variables";

body#had {
  #main,
  #header,
  #footer {
    ul#search_pagination {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        color: $lightblue;
        display: inline-block;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
        &.current {
          opacity: 0.5;
        }
        a {
          &.current {
            opacity: 0.5;
          }
        }
      }
    }
    form.ccm-search-block-form:not(.hs-form) {
      position: relative;
      margin-top: 5em;
      input[type="text"] {
        border: 0 !important;
        border-bottom: 1px solid $textcolor !important;
        border-radius: 0 !important;
        font-style: normal !important;
        font-weight: 200 !important;
        font-size: 3em !important;
        background-color: transparent;
        &::placeholder {
          font-family: "Lato", sans-serif;
          color: $textcolor;
          font-style: normal;
          font-weight: 200 !important;
        }
      }
      button[name="submit"] {
        position: absolute;
        bottom: 12px;
        right: 25px;
        background-color: transparent;
        border: 0px;
        padding: 0;
        color: $lightblue;
        min-width: 0;
        font-size: 2.75em;
      }
    }
    div.searchResults--container {
      .searchResults--count {
        h4 {
          font-size: 1.25em;
          font-weight: 700;
          font-style: italic;
        }
      }
      #searchResults {
        .searchResult {
          margin-block-end: 1.5em;
          margin-bottom: 1.5em;
          h3.searchResult--title {
            margin-block-end: 0.5em;
            margin-bottom: 0.5em;
            font-size: 1.4em;
            font-weight: 700;
          }
          .searchResult--description {
            margin-block-end: 0.5em;
            margin-bottom: 0.5em;
            font-size: 1.15em;
            font-weight: 400;
            .pageLink {
              margin-block-start: 0.5em;
              margin-top: 0.5em;
              margin-block-end: 0.5em;
              margin-bottom: 0.5em;
              font-size: 0.9em;
              font-style: italic;
              color: $lightblue;
            }
          }
        }
      }
    }
  }
}
