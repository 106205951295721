@import "variables";
html.ccm-toolbar-visible {
  margin-top: 48px !important;
}
html.ccm-panel-detail-open .ccm-panel-detail {
  display: block;
}
body#had,
html {
  &.menu-open {
    overflow: hidden;
  }
}
body#had {
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 80%;
  // max-width: 1920px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: $textcolor;
  margin-top: 85px;
  #main,
  footer#footer,
  .pre-footer header#header {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  &.loggedin {
    nav.desktop {
      margin-top: 30px;
    }
  }
  #main {
    margin-top: 137px;
    .rslides_tabs {
      text-align: center;
    }
    img:not([src]):not([srcset]) {
      visibility: hidden;
    }
    .container {
      .col {
        width: 100%;
      }
    }
    > .row,
    .ccm-block-edit-layout > .row {
      > div {
        > .col-sm-30.columns {
          @include container();
        }
      }
      &.narrow {
        > .container {
          @include container();
          max-width: 1035px;
          // max-width: 998px;
        }
      }
      &.medium {
        > .container {
          @include container();
          max-width: 1200px;
          // max-width: 998px;
        }
      }
      &.two {
        &.arrows {
          .col {
            background-color: #fafafa;
            position: relative;
            overflow: hidden;
            margin-bottom: 3em;
            border-radius: 12px;
            .content {
              padding: 2.9em 3em;
            }
            &:before {
              content: "";
              position: absolute;
              top: -30px;
              right: 0;
              transform: translate(-10%, 0px);
              width: 100%;
              height: calc(100% + 40px);
              opacity: 0.02;
              background-size: 100%;
              background-position: center left;
              background-repeat: no-repeat;
              background-image: url($herochevrondark);
              z-index: 2;
              overflow: hidden;
              pointer-events: none;
              @include transition(transform, 1s, ease-out, 0s);
              @media screen and (min-width: 768px) {
                width: 1000px;
              }
            }
            h4 {
              font-size: 1.67em;
              font-weight: 700;
              margin-bottom: 0em;
              + p {
                margin-top: 0.5em;
              }
            }
            &:nth-child(4n + 1) {
              h4 {
                color: $berry;
              }
              &:before {
                background-image: url($herochevronberry);
              }
            }
            &:nth-child(4n + 2) {
              h4 {
                color: $lightblue;
              }
              &:before {
                background-image: url($herochevronlightblue);
              }
            }
            // &:nth-child(4n + 3) {
            //   h4 {
            //     color: $green;
            //   }
            //   &:before {
            //     background-image: url($herochevrongreen);
            //   }
            // }
            // &:nth-child(4n + 4) {
            //   h4 {
            //     color: $gold;
            //   }
            //   &:before {
            //     background-image: url($herochevrongold);
            //   }
            // }
          }
          &:nth-child(even) {
            .col {
              &:nth-child(odd) {
                h4 {
                  color: $berry;
                }
                &:before {
                  background-image: url($herochevronberry);
                }
              }
              &:nth-child(even) {
                h4 {
                  color: $lightblue;
                }
                &:before {
                  background-image: url($herochevronlightblue);
                }
              }
            }
          }
          &:nth-child(odd) {
            .col {
              &:nth-child(odd) {
                h4 {
                  color: $green;
                }
                &:before {
                  background-image: url($herochevrongreen);
                }
              }
              &:nth-child(even) {
                h4 {
                  color: $gold;
                }
                &:before {
                  background-image: url($herochevrongold);
                }
              }
            }
          }
        }
      }
      &.four {
        > .container {
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          @include container();
        }
      }
      &.five {
        > .container {
          @include container();
          .col {
            margin-bottom: 2.5em;
          }
        }
      }

      .flex-container {
        @include container();
        max-width: 1500px;
        padding: 20px;

        .flex-item {
          width: 100%;
          margin-bottom: 40px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .left {
            width: 100%;
            margin-bottom: 10px;

            img {
              width: 100%;
            }
          }
        }

        @media only screen and (min-width: 768px) {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 20px;

          .flex-item {
            width: 47.5%;
            margin-right: 5%;
            margin-bottom: 55px;

            .left {
              width: 250px;
              margin-right: 30px;
            }

            .right {
              width: calc(100% - 280px);
            }

            &:nth-child(even) {
              margin-right: 0;
            }
          }
        }
        @media only screen and (min-width: 1200px) {
          .flex-item {
            .left {
              width: 320px;
            }
            .right {
              width: calc(100% - 350px);
            }
          }
        }
      }
    }
    #ot-sdk-cookie-policy {
      @include container();
      max-width: 1035px;
    }
    .two{
      .container{
        display: flex;
        gap: 2em;
        flex-wrap: wrap;
        justify-content: center;
        .col{
          max-width: 600px;
        }
      }
    }
    .three{
      .container{
        display: flex;
        gap: 2em;
        flex-wrap: wrap;
        justify-content: center;
        .col{
          max-width: 390px;
        }
      }
    }
    .four{
      .container{
        display: flex;
        gap: 2em;
        flex-wrap: wrap;
        justify-content: center;
        .col{
          max-width: 284px;
        }
      }
    }
    .five{
      .container{
        display: flex;
        gap: 2em;
        flex-wrap: wrap;
        justify-content: center;
        .col{
          max-width: 221px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      margin-top: 0;
    }
  }/*
  @media only screen and (min-width: 768px) {
    font-size: 85%;
    margin-top: 105px;
    #main {
      .row,
      .ccm-block-edit-layout {
        > .container {
          @include container();
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
        }
        &.four {
          > .container {
            .col {
              width: calc(48.5% - 4px);
              margin-inline-end: 3%;
              &:nth-child(even) {
                margin-right: 0;
              }
            }
          }
        }
        &.five {
          > .container {
            > .col {
              width: 47.5%;
              margin-right: 5%;
              *:first-child {
                margin-top: 0;
              }
              *:last-child {
                margin-bottom: 0;
              }
              &:nth-child(even) {
                margin-right: 0;
              }
              &:last-child {
                margin-right: 0 !important;
              }
            }
          }
        }
        &.two {
          > .container {
            > .col {
              width: 47.5%;
              margin-right: 5%;
              *:first-child {
                margin-top: 0;
              }
              *:last-child {
                margin-bottom: 0;
              }
              &:nth-child(even) {
                margin-right: 0;
              }
              &:last-child {
                margin-right: 0 !important;
              }
            }
          }
        }
        &.three {
          > .container {
            > .col {
              width: 47.5%;
              margin-right: 5%;
              *:first-child {
                margin-top: 0;
              }
              *:last-child {
                margin-bottom: 0;
              }
              &:nth-child(even) {
                margin-right: 0;
              }
              &:last-child {
                margin-right: 0 !important;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 960px) {
    font-size: 90%;
    #main {
      .row,
      .ccm-block-edit-layout {
        &.three {
          > .container {
            > .col {
              width: 30%;
              &:nth-child(even) {
                margin-right: 5%;
              }
              &:nth-child(3n + 3) {
                margin-right: 0;
              }
            }
          }
        }
        &.four {
          > .container {
            > .col {
              width: calc(94% / 3 - 4px);
              &:nth-child(even) {
                margin-right: 3%;
              }
              &:nth-child(3n + 3) {
                margin-right: 0;
              }
            }
          }
        }
        &.five {
          > .container {
            > .col {
              width: 30%;
              &:nth-child(even) {
                margin-right: 5%;
              }
              &:nth-child(3n + 3) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1024px) {
    margin-top: 137px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 95%;
    #main {
      .row,
      .ccm-block-edit-layout {
        &.four {
          > .container {
            > .col {
              width: calc(91% / 4 - 4px);
              &:nth-child(even) {
                margin-right: 3%;
              }
              &:nth-child(3n + 3) {
                margin-right: 3%;
              }
              &:nth-child(4n + 4) {
                margin-right: 0%;
              }
            }
          }
        }
        &.five {
          > .container {
            > .col {
              width: calc(80% / 5);
              margin-bottom: 0;
              &:nth-child(even) {
                margin-right: 5%;
              }
              &:nth-child(3n + 3) {
                margin-right: 5%;
              }
            }
          }
        }
      }
    }
  }*/
  @media only screen and (min-width: 1500px) {
    font-size: 80%;
  }

  .ccm-custom-style-container {
    .container{
      .col {
        border-radius: 12px;
        border-style: solid;
        border-width: 2px;
        padding: 2em;
      }

      @media screen and (max-width:768px) {
        margin:16px;
      }
    }
    &.col-border-berry {
      .col {
        border-color: #840b55;
        h2, a{
          color:#840b55 !important;
          margin-block-start: 0 !important;
        }
        @media screen and (max-width:1200px) {
          margin-bottom:1em;
        }
      }
    }

    &.col-border-dark-blue {
      .col {
        border-color: #3a5382;
        h2, a{
          color:#3a5382 !important;
          margin-block-start: 0 !important;
        }
        @media screen and (max-width:1200px) {
          margin-bottom:1em;
        }
      }
    }

    &.col-border-graphite {
      .col {
        border-color: #53565a;
        h2, a{
          color:#53565a !important;
          margin-block-start: 0 !important;
        }
        @media screen and (max-width:1200px) {
          margin-bottom:1em;
        }
      }
    }

    &.col-border-green {
      .col {
        border-color: #006e42;
        h2, a{
          color:#006e42 !important;
          margin-block-start: 0 !important;
        }
        @media screen and (max-width:1200px) {
          margin-bottom:1em;
        }
      }
    }

    &.col-border-light-blue {
      .col {
        border-color: #0079a3;
        h2, a{
          color:#0079a3 !important;
          margin-block-start: 0 !important;
        }
        @media screen and (max-width:1200px) {
          margin-bottom:1em;
        }
      }
    }
    &.col-border-all-color {
      .col {
        &:nth-child(1){
          border-color: #840b55;
          h2, a{
            color:#840b55 !important;
            margin-block-start: 0 !important;
          }
        }
        &:nth-child(2){
          border-color: #3a5382;
          h2, a{
            color:#3a5382 !important;
            margin-block-start: 0 !important;
          }
        }
        &:nth-child(3){
          border-color: #006e42;
          h2, a{
            color:#006e42 !important;
            margin-block-start: 0 !important;
          }
        }
        &:nth-child(4){
          border-color: #0079a3;
          h2, a{
            color:#0079a3 !important;
            margin-block-start: 0 !important;
          }
        }
        &:nth-child(5){
          border-color: #c5732c;
          h2, a{
            color:#c5732c !important;
            margin-block-start: 0 !important;
          }
        }
        @media screen and (max-width:1200px) {
          margin-bottom:1em;
        }
      }
    }
  }
}