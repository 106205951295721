@import "variables";

body#had {
  #main {
    #expert_row {
      margin-top: 5em;
      .container {
        @include container();
        max-width: 1250px;
      }
      .key {
        background-color: #f4f4f4;
        margin: 2.5em auto;
        border-radius: 12px;
        ul {
          list-style: none;
          margin: 0;
          padding: 0.8em 1.3em;
          font-size: 1em;
          li {
            font-weight: 400;
            display: flex;
            &:before {
              content: "";
              display: inline-block;
              width: 15px;
              height: 15px;
              border-radius: 50%;
              margin-right: 10px;
              position: relative;
              top: 2px;
            }
            &:nth-child(1) {
              &:before {
                background-color: #731743;
              }
            }
            &:nth-child(2) {
              &:before {
                background-color: #11365b;
              }
            }
            &:nth-child(3) {
              &:before {
                background-color: #11643c;
              }
            }
            &:nth-child(4) {
              &:before {
                background-color: #0a7198;
              }
            }
            &:nth-child(5) {
              &:before {
                background-color: #d08a25;
              }
            }
          }
        }
      }
      .right {
        margin-top: 2.5em;
      }
      .left {
        img {
          margin-bottom: 1em;
        }
        h1 {
          font-weight: bold;
          font-size: 2em;
          margin: 0;
        }
        h1 + p {
          font-size: 1.5em;
          margin: 0.15em 0 0.75em;
        }
        hr {
          opacity: 0.2;
          margin: 15px 0px;
        }
        p.practices {
          font-size: 1.4em;
          margin-bottom: 0.25em;
          word-wrap: break-word;
          -webkit-hyphens: auto;
          -moz-hyphens: auto;
          -ms-hyphens: auto;
          hyphens: auto;
        }
        a {
          color: #0a7198;
          &:hover {
            text-decoration: underline;
          }
        }
        span.dots {
          position: relative;
          top: 0px;
          margin-right: 20px;
          span {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid #3d464c;
            opacity: 0.3;
            margin-right: 8px;
            &:last-child {
              margin-right: 0;
            }
            &.active {
              opacity: 1;
              position: relative;
              &:nth-child(1) {
                border-color: #731743;
                background-color: #731743;
              }
              &:nth-child(2) {
                border-color: #11365b;
                background-color: #11365b;
              }
              &:nth-child(3) {
                border-color: #11643c;
                background-color: #11643c;
              }
              &:nth-child(4) {
                border-color: #0a7198;
                background-color: #0a7198;
              }
              &:nth-child(5) {
                border-color: #d08a25;
                background-color: #d08a25;
              }
              &:hover {
                .tooltip {
                  background: $textcolor;
                  border-radius: 8px;
                  border: none;
                  color: white;
                  display: inline;
                  font-size: 14px;
                  height: auto;
                  opacity: 1;
                  padding: 4px 8px;
                  position: absolute;
                  top: 24px;
                  width: max-content;
                }
              }
            }
            &.tooltip {
              display: none;
            }
          }
        }
      }
      @media only screen and (min-width: 768px) {
        .container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .left {
            width: 250px;
            margin-right: 50px;
            font-size: 85%;
            img {
              width: 100%;
            }
          }
          .right {
            width: calc(100% - 300px);
            margin-top: 0;
            *:first-child {
              margin-top: 0;
            }
          }
        }
      }
      @media only screen and (min-width: 768px) {
        .key {
          ul {
            text-align: center;
            padding: 1.3em 1.3em 0.8em;
            li {
              display: inline-block;
              margin-right: 40px;
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
      @media only screen and (min-width: 960px) {
        .container {
          .left {
            width: 300px;
            font-size: 80%;
          }
          .right {
            width: calc(100% - 350px);
          }
        }
      }
      @media only screen and (min-width: 1200px) {
        .container {
          .left {
            width: 350px;
          }
          .right {
            width: calc(100% - 400px);
          }
        }
      }
    }
  }
}
