@import "variables";

body#had {
  header#header {
    background-color: white;
    width: 100%;
    height: 85px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    &.headroom {
      will-change: transform;
      transition: transform 200ms linear;
    }
    &.headroom--pinned {
      transform: translateY(0%);
    }
    &.headroom--unpinned {
      transform: translateY(-100%);
    }
    #search_overlay {
      position: fixed;
      top: 85px;
      left: 0;
      width: 100%;
      height: calc(100vh - 85px);
      background-color: hsla(0, 0%, 100%, 0.97);
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      @include transition(height, 0.25s, ease-out, 0s);
      form.ccm-search-block-form {
        @include container();
        max-width: 1280px;
        top: -7.5%;
      }
      &:not(.open) {
        height: 0;
      }
    }
    #mobile_nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      #close_btn_holder_mobile {
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 100%;
        background-color: white;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        z-index: 2;
        @include transition(all, 0.15s, ease-out, 0s);
        &.open {
          width: calc(100% - 150px);
        }
        a {
          &:hover {
            svg {
              fill: $lightblue;
              path {
                fill: $lightblue;
              }
            }
          }
        }
      }
      .search {
        .fa-xmark {
          display: none;
        }
      }
    }
    #desktop_nav {
      display: none;
    }
    .container {
      @include container();
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      a.logo {
        width: 130px;
      }
    }
    a {
      &.search {
        font-size: 20px;
        margin-right: 25px;
      }
      &.language {
        margin-right: 30px;
      }
    }
    .menu_icon {
      display: inline-block;
      width: 40px;
      height: 30px;
      position: relative;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #4e565b;
        border-radius: 3px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
      }
      span:nth-child(1) {
        top: 0px;
      }
      span:nth-child(2),
      span:nth-child(3) {
        top: 13px;
      }
      span:nth-child(4) {
        top: 25px;
      }
      &.open {
        span:nth-child(1) {
          top: 13px;
          width: 0%;
          left: 50%;
        }
        span:nth-child(2) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        span:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
        span:nth-child(4) {
          top: 13px;
          width: 0%;
          left: 50%;
        }
      }
    }
    @media only screen and (min-width: 768px) {
      height: 105px;
      #search_overlay {
        top: 105px;
        height: calc(100vh - 105px);
      }
      .container {
        a.logo {
          width: 150px;
        }
      }
    }
    @media only screen and (min-width: 1024px) {
      height: 137px;
      #search_overlay {
        top: 137px;
        height: calc(100vh - 137px);
      }
      #mobile_nav {
        display: none;
      }
      .container {
        a.logo {
          width: 187px;
        }
      }
      #desktop_nav {
        display: flex;
        flex-direction: column;
        position: relative;
        #utility_nav {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 25px;
          a {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-weight: 400;
            font-size: 16px;
            margin-right: 0;
            img,
            i {
              margin-left: 10px;
            }
            &.language {
              border-left: 1px solid $textcolor;
              margin-right: 0;
              margin-left: 25px;
              padding-left: 25px;
              img {
                width: 18px;
              }
            }
          }
        }
        #close_btn_holder {
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 0;
          width: 0;
          height: 100%;
          background-color: white;
          text-align: right;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          @include transition(all, 0.15s, ease-out, 0s);
          &.open {
            width: 100%;
          }
          a {
            &:hover {
              svg {
                fill: $lightblue;
                path {
                  fill: $lightblue;
                }
              }
            }
          }
        }
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          font-size: 1.28em;
          li {
            display: inline-block;
            margin: 0 1.75em 0 0px;
            &.contact {
              margin-right: 0;
            }
            &.search,
            &.language {
              display: none;
            }
            a {
              display: block;
              font-weight: 700;
              margin: 0;
              &.active,
              &.nav-selected {
                text-decoration: underline;
              }
              &:hover {
                text-decoration: underline;
              }
              &.btn {
                font-size: 16px;
                min-width: 0px;
                padding: 10px 0;
                width: 120px;
                font-weight: 700;
                text-align: center;
                &:after {
                  display: none;
                }
                &:hover {
                  text-decoration: none;
                }
              }
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  &.loggedin {
    header#header {
      top: 48px;
    }
  }
}
