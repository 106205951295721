@import "variables";

body#had {
  #main,
  #header,
  #footer,
  #gated_content_form {
    h3.response {
      position: absolute;
      font-size: 1.5em !important;
    }
    form:not(#ccm-block-form):not(#clickdimensionsForm):not(.hs-form) {
      overflow: visible;
      opacity: 1;
      &#resources_filter {
        overflow: visible;
      }
      @include transition(opacity, 0.15s, ease-out, 0s);
      .row {
        .form-group {
          width: 100%;
          margin-bottom: 25px;
          position: relative;
          p {
            font-size: 1em;
            a {
              color: white;
              text-decoration: underline;
            }
          }
        }
        &:last-child {
          .form-group {
            margin-bottom: 0px;
          }
        }
      }
      label {
        font-size: 18px;
        pointer-events: none;
        span {
          color: $berry;
          > a {
            color: $berry;
          }
        }
      }
      input[type="submit"] {
        margin-top: 5px;
      }
      input[type="text"],
      input[type="num"],
      input[type="tel"],
      input[type="email"],
      input[type="url"],
      input[type="date"] {
        box-sizing: border-box;
        width: 100%;
        border: 2px solid #fff;
        border-radius: 12px;
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-size: 18px;
        padding: 12px;
        @include transition(border-color, 0.15s, ease-out, 0s);
        &:hover,
        &:focus {
          border-color: $lightblue;
          outline: 0;
        }
        &::placeholder {
          font-family: "Lato", sans-serif;
          color: $textcolor;
          font-weight: 400;
        }
        &[required] {
          &::placeholder {
            opacity: 0;
          }
        }
        + label {
          display: none;
        }
        &:placeholder-shown {
          &[required] {
            &::placeholder {
              opacity: 0;
            }
            + label {
              display: block;
              position: absolute;
              left: 14px;
              top: 14px;
              cursor: text;
            }
          }
        }
      }
      .choices {
        position: relative;
        outline: none !important;
        .choices__inner {
          .choices__list {
            > .choices__item {
              color: $lightblue;
              font-style: italic;
              border: 2px solid $lightblue;
              padding: 12px;
              border-radius: 12px;
              font-size: 18px;
              font-size: 400;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              &:after {
                content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="11.047" height="6.298" viewBox="0 0 11.047 6.298"><path id="Shape_1_copy" data-name="Shape 1 copy" d="M558.588,1887.272h0l5.075-4.1a1.224,1.224,0,0,0,.139-1.76h0a1.326,1.326,0,0,0-1.821-.135l-3.393,2.85-3.393-2.85a1.326,1.326,0,0,0-1.821.135h0a1.223,1.223,0,0,0,.139,1.76l5.075,4.1Z" transform="translate(-553.064 -1880.974)" fill="#057ca3"/></svg>');
                position: relative;
                top: -3px;
                @include transition(all, 0.15s, ease-out, 0s);
              }
            }
          }
        }
        &.is-open {
          .choices__inner {
            .choices__list {
              > .choices__item {
                &:after {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
        .choices__list--dropdown {
          border: 2px solid $lightblue;
          border-radius: 12px;
          position: absolute;
          width: 100%;
          max-height: 0;
          overflow-x: hidden;
          overflow-y: scroll;
          opacity: 0;
          background-color: white;
          z-index: 2;
          @include transition(all, 0.25s, ease-out, 0s);
          &.is-active {
            opacity: 1;
            max-height: 500px;
          }
          input[type="search"] {
            box-sizing: border-box;
            width: 75%;
            max-width: 550px;
            min-width: 300px;
            border: 0;
            border-bottom: 1px solid rgba(70, 80, 87, 0.2);
            font-family: "Lato", sans-serif;
            font-weight: 400;
            font-size: 16px;
            padding: 7px 12px;
            background-color: transparent;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            &:hover,
            &:focus {
              border-color: $lightblue;
              outline: 0;
            }
          }
          .choices__list {
            .choices__item {
              padding: 7px 12px;
              font-size: 16px;
              border-bottom: 1px solid rgba(70, 80, 87, 0.2);
              @include transition(all, 0.15s, ease-out, 0s);
              &:not(.choices__placeholder) {
                cursor: pointer;
                &:hover {
                  color: white;
                  background-color: $lightblue;
                }
              }
              &:last-child {
                border-bottom: 0;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
              }
            }
          }
        }
      }
      input[type="radio"] {
        width: 25px;
        height: 25px;
        position: absolute;
        opacity: 0;
        z-index: 2;
        top: -5px;
        left: -3px;
        + label {
          font-size: 16px;
          line-height: 18px;
          display: flex;
          flex-direction: row;
          margin-right: 10px;
          &:before {
            content: "";
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background-color: white;
            border: 2px solid rgba(70, 80, 87, 0.2);
            display: inline-block;
            position: relative;
            top: -4px;
            margin-right: 10px;
            align-self: flex-start;
            flex: 0 0 25px;
          }
          &:after {
            content: "";
            width: 12px;
            height: 12px;
            background-color: $lightblue;
            position: absolute;
            left: 8.5px;
            top: 4.5px;
            z-index: 2;
            border-radius: 50%;
            transform: scale(0);
            align-self: flex-start;
            flex: 0 0 12px;
            @include transition(all, 0.1s, ease-out, 0s);
          }
        }
        &:checked {
          + label {
            &:after {
              transform: scale(1);
            }
          }
        }
      }
      input[type="checkbox"] {
        width: 25px;
        height: 25px;
        position: absolute;
        opacity: 0;
        z-index: 2;
        top: -5px;
        left: -3px;
        + label {
          font-size: 16px;
          line-height: 18px;
          display: flex;
          flex-direction: row;
          margin-right: 10px;
          &:before {
            content: "";
            width: 25px;
            height: 25px;
            background-color: white;
            border: 2px solid rgba(70, 80, 87, 0.2);
            display: inline-block;
            position: relative;
            top: -4px;
            margin-right: 10px;
            align-self: flex-start;
            flex: 0 0 25px;
          }
          &:after {
            content: "";
            width: 12px;
            height: 12px;
            background-color: $lightblue;
            position: absolute;
            left: 8.5px;
            top: 4.5px;
            z-index: 2;
            transform: scale(0);
            align-self: flex-start;
            flex: 0 0 12px;
            @include transition(all, 0.1s, ease-out, 0s);
          }
        }
        &:checked {
          + label {
            &:after {
              transform: scale(1);
            }
          }
        }
      }
      @media only screen and (min-width: 960px) {
        .row {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .form-group {
            &.half,
            &.third,
            &.fourth {
              margin-right: 3%;
            }
            &.first {
              margin-left: 0;
            }
            &.last {
              margin-right: 0;
            }
            &.third {
              width: calc(94% / 3);
            }
            &.half {
              width: calc(97% / 2);
            }
          }
        }
      }
    }

    .clickdimensions-form * {
      border: inherit !important;
      color: #fff !important;
      font-family: inherit !important;
      font-size: inherit !important;
      font-weight: inherit !important;
      margin: inherit !important;
      max-height: inherit !important;
      max-width: inherit !important;
      min-height: inherit !important;
      min-width: inherit !important;
      padding: inherit !important;
      vertical-align: inherit !important;

      button:focus,
      input:focus,
      select:focus,
      textarea:focus {
        outline: none;
      }

      input {
        &[type=text] {
          border-radius: 12px !important;
          color: #000 !important;
          margin-top: 6px !important;
          padding: 12px !important;
        }

        &[type=checkbox] {
          margin-right: 4px !important;
        }

        &[type=button] {
          background-color: #0079a3 !important;
          border-radius: 12px !important;
          color: #fff !important;
          font-style: italic !important;
          font-weight: 700 !important;
          padding: 12px !important;
        }
      }

      a {
        text-decoration: underline !important;
      }

      .requiredInfo * {
        color: #ffff00 !important;
        margin-top: 4px !important;
      }
    }
  }

  #gated_content_form {
    display: none;
    border-radius: 12px;
    width: 95%;
    max-width: 750px;
    overflow: scroll;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    * {
      font-family: "Lato", sans-serif;
    }
    .top-content {
      p {
        font-size: 1.5em !important;
      }
    }
    input[type="submit"] {
      cursor: pointer;
      background-color: $lightblue;
      border: 2px solid $lightblue;
      border-radius: 12px;
      color: white;
      font-style: italic;
      font-weight: 700;
      font-size: 18px;
      font-family: "Lato", sans-serif;
      padding: 17px 21px;
      position: relative;
      // min-width: 148px;
      @include transition(all, 0.15s, ease-out, 0s);
      &:hover,
      &:focus {
        background-color: #006182;
        border-color: #006182;
      }
    }
    .form-group {
      > p {
        > span {
          > a {
            color: $berry !important;
            text-decoration: underline !important;
          }
        }
      }
    }
    form:not(#ccm-block-form),
    form:not(.hs-form) {
      input[type="text"],
      input[type="num"],
      input[type="tel"],
      input[type="email"],
      input[type="url"],
      input[type="date"] {
        border-color: $graphite;
      }
      input[type="radio"] {
        &:checked {
          + label {
            &:after {
              left: 6.5px;
              top: 2.5px;
            }
          }
        }
      }
    }
    p.back {
      position: absolute;
      top: 5px;
      left: 25px;
      font-size: 16px;
      z-index: 2;
      a {
        font-weight: 700;
        font-style: italic;
        font-family: "Lato", sans-serif;
        cursor: pointer;
        font-size: 16px;
        color: $graphite;
        text-decoration: none;
        outline: none;
        &:before {
          content: url($backarrow);
          // transform: rotate(180deg);
          display: inline-block;
          position: relative;
          top: -2px;
          margin-right: 6px;
          font-style: normal;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    h3 {
      font-size: em(30);
      font-weight: 400;
      margin: 0;
      margin-bottom: 0.25em;
      @media only screen and (min-width: 768px) {
        font-size: em(36);
      }
    }
    p {
      font-size: em(18);
      line-height: em(24);
      margin: 0;
      position: relative;
    }
    form {
      font-size: 14px;
      margin-top: 2em;
    }
    .container {
      max-width: 100%;
      width: calc(100% - 50px);
      margin: 0 auto;
    }
    button.submit {
      text-decoration: none;
      background-color: $lightblue;
      border-radius: 12px;
      color: $graphite;
      outline: 0px;
      border: 1px solid $lightblue;
      font-size: em(18);
      font-weight: 700;
      font-style: italic;
      font-family: "Lato", sans-serif;
      padding: 0px;
      margin: 0;
      cursor: pointer;
      display: inline-block;
      padding: rem(13.25) rem(20);
      position: relative;
      @include transition(all, 0.15s, linear, 0s);
      &:before {
        content: "";
        width: em(34);
        height: em(34);
        background-color: white;
        position: absolute;
        right: em(17.5);
        border-radius: 50%;
        transform: scale(0.8);
        @media only screen and (max-width: 767px) {
          font-size: 16px;
        }
        margin: auto;
        top: 0 !important;
        bottom: 0;
        background-image: url($backarrow);
        background-size: em(11);
        background-position: 54% 50%;
        background-repeat: no-repeat;
        @include transition(all, 0.15s, ease-out, 0s);
      }
      &:after {
        content: "";
        margin-left: em(30);
        vertical-align: middle;
        width: em(28);
        height: em(28);
        background-size: em(10);
        z-index: 2;
        position: relative;
        left: em(-1);
        top: em(2.5);
        display: inline-block;
        background-image: url($backarrow);
        text-align: center;
        opacity: 0;
        @media only screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
      &.pdf {
        padding: rem(13.25) rem(40) rem(13.25) rem(20);
        &:hover {
          &:before {
            background-size: em(12.5);
          }
        }
      }
      &:hover {
        background-color: $darkblue;
        border-color: $darkblue;
        &:before {
          transform: scale(1);
          background-size: em(9);
        }
      }
      &[disabled] {
        background-color: #e8e8e8;
        border-color: #888888;
        cursor: default;
        &:hover {
          background-color: #e8e8e8;
          border-color: #888888;
          &:before {
            background-size: em(11);
            transform: scale(0.8);
          }
        }
      }
    }
    &.has_img {
      max-width: 1000px;
      padding: 0;
      .img_col {
        overflow: hidden;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        height: 250px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .content_col {
        .container {
          padding-top: 25px;
          padding-bottom: 25px;
        }
      }
      @media only screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .content_col {
          order: 1;
          width: 55%;
          .container {
            padding-top: 50px;
            padding-bottom: 50px;
          }
        }
        .img_col {
          order: 2;
          width: 45%;
          border-top-left-radius: 0;
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
          overflow: hidden;
          max-height: 100%;
          height: auto;
        }
      }
    }
  }

  #gated_insight_form {
    input[type='date'],
    input[type='email'],
    input[type='num'],
    input[type='tel'],
    input[type='text'],
    input[type='url'] {
      border: 2px solid rgba(70, 80, 87, 0.2) !important;
    }
  }
}