@import "variables";

body#had {
  #main,
  header#header,
  footer#footer,
  nav {
    .load_more_holder {
      p.loadmore {
        text-align: center;
      }
    }

    a.btn,
    button,
    input[type="submit"] {
      cursor: pointer;
      background-color: $lightblue;
      border: 2px solid $lightblue;
      border-radius: 12px;
      color: white;
      font-style: italic;
      font-weight: 700;
      font-size: 18px;
      font-family: "Lato", sans-serif;
      padding: 17px 21px;
      position: relative;
      // min-width: 148px;
      @include transition(all, 0.15s, ease-out, 0s);
      &.ccm-search-block-submit {
        &:after {
          display: none;
        }
      }
      &:not(.accordion-button) {
        &:after {
          content: url($doublewhite);
          padding-left: 14px;
          @include transition(all, 0.15s, ease-out, 0s);
        }
      }
      &:not(.accordion-button) {
        &:hover,
        &:focus {
          background-color: #006182;
          border-color: #006182;

          // &:after {
          //   content: url($doubleblue);
          // }
        }
      }

      &.md {
        padding: 11px 15px;
      }

      &.sm {
        &:after {
          display: none;
        }

        font-size: 16px;
        padding: 9.5px 15px;
      }

      &.ghost {
        background-color: white;
        color: $lightblue;
        padding: 17px 21px;

        &:after {
          padding-left: 23px;
          content: url($bluearrows);
        }

        &:hover,
        &:focus {
          background-color: $lightblue;
          color: white;

          &:after {
            content: url($whitearrows);
          }
        }

        &.md {
          padding: 11px 15px;
        }

        &.sm {
          font-size: 18px;
          padding: 8px 15px;
        }
      }
    }
  }
}
