@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Bold.eot");
  src: local("Lato Bold"), local("Lato-Bold"),
    url("../fonts/Lato-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Lato-Bold.woff2") format("woff2"),
    url("../fonts/Lato-Bold.woff") format("woff"),
    url("../fonts/Lato-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Lato Hairline";
  src: url("../fonts/Lato-HairlineItalic.eot");
  src: local("Lato-HairlineItalic"),
    url("../fonts/Lato-HairlineItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Lato-HairlineItalic.woff2") format("woff2"),
    url("../fonts/Lato-HairlineItalic.woff") format("woff"),
    url("../fonts/Lato-HairlineItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-BlackItalic.eot");
  src: local("Lato Black Italic"), local("Lato-BlackItalic"),
    url("../fonts/Lato-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Lato-BlackItalic.woff2") format("woff2"),
    url("../fonts/Lato-BlackItalic.woff") format("woff"),
    url("../fonts/Lato-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-BoldItalic.eot");
  src: local("Lato Bold Italic"), local("Lato-BoldItalic"),
    url("../fonts/Lato-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Lato-BoldItalic.woff2") format("woff2"),
    url("../fonts/Lato-BoldItalic.woff") format("woff"),
    url("../fonts/Lato-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Black.eot");
  src: local("Lato Black"), local("Lato-Black"),
    url("../fonts/Lato-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Lato-Black.woff2") format("woff2"),
    url("../fonts/Lato-Black.woff") format("woff"),
    url("../fonts/Lato-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Lato Hairline";
  src: url("../fonts/Lato-Hairline.eot");
  src: local("Lato-Hairline"),
    url("../fonts/Lato-Hairline.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Lato-Hairline.woff2") format("woff2"),
    url("../fonts/Lato-Hairline.woff") format("woff"),
    url("../fonts/Lato-Hairline.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Italic.eot");
  src: local("Lato Italic"), local("Lato-Italic"),
    url("../fonts/Lato-Italic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Lato-Italic.woff2") format("woff2"),
    url("../fonts/Lato-Italic.woff") format("woff"),
    url("../fonts/Lato-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-LightItalic.eot");
  src: local("Lato-LightItalic"),
    url("../fonts/Lato-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Lato-LightItalic.woff2") format("woff2"),
    url("../fonts/Lato-LightItalic.woff") format("woff"),
    url("../fonts/Lato-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Light.eot");
  src: local("Lato-Light"),
    url("../fonts/Lato-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Lato-Light.woff2") format("woff2"),
    url("../fonts/Lato-Light.woff") format("woff"),
    url("../fonts/Lato-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Regular.eot");
  src: local("Lato Regular"), local("Lato-Regular"),
    url("../fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Lato-Regular.woff2") format("woff2"),
    url("../fonts/Lato-Regular.woff") format("woff"),
    url("../fonts/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-ExtraBold.eot");
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
    url("../fonts/OpenSans-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-ExtraBold.woff2") format("woff2"),
    url("../fonts/OpenSans-ExtraBold.woff") format("woff"),
    url("../fonts/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-Bold.eot");
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url("../fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-Bold.woff2") format("woff2"),
    url("../fonts/OpenSans-Bold.woff") format("woff"),
    url("../fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-SemiBoldItalic.eot");
  src: local("Open Sans SemiBold Italic"), local("OpenSans-SemiBoldItalic"),
    url("../fonts/OpenSans-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/OpenSans-SemiBoldItalic.woff2") format("woff2"),
    url("../fonts/OpenSans-SemiBoldItalic.woff") format("woff"),
    url("../fonts/OpenSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-ExtraBoldItalic.eot");
  src: local("Open Sans ExtraBold Italic"), local("OpenSans-ExtraBoldItalic"),
    url("../fonts/OpenSans-ExtraBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/OpenSans-ExtraBoldItalic.woff2") format("woff2"),
    url("../fonts/OpenSans-ExtraBoldItalic.woff") format("woff"),
    url("../fonts/OpenSans-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-SemiBold.eot");
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url("../fonts/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-SemiBold.woff2") format("woff2"),
    url("../fonts/OpenSans-SemiBold.woff") format("woff"),
    url("../fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-BoldItalic.eot");
  src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"),
    url("../fonts/OpenSans-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-BoldItalic.woff2") format("woff2"),
    url("../fonts/OpenSans-BoldItalic.woff") format("woff"),
    url("../fonts/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-LightItalic.eot");
  src: local("Open Sans Light Italic"), local("OpenSans-LightItalic"),
    url("../fonts/OpenSans-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-LightItalic.woff2") format("woff2"),
    url("../fonts/OpenSans-LightItalic.woff") format("woff"),
    url("../fonts/OpenSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-Light.eot");
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url("../fonts/OpenSans-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-Light.woff2") format("woff2"),
    url("../fonts/OpenSans-Light.woff") format("woff"),
    url("../fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-Regular.eot");
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("../fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-Regular.woff2") format("woff2"),
    url("../fonts/OpenSans-Regular.woff") format("woff"),
    url("../fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-Italic.eot");
  src: local("Open Sans Italic"), local("OpenSans-Italic"),
    url("../fonts/OpenSans-Italic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-Italic.woff2") format("woff2"),
    url("../fonts/OpenSans-Italic.woff") format("woff"),
    url("../fonts/OpenSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
