@import "variables";

body#had {
  nav {
    &.breadcrumb {
      background-color: $textcolor;
      ol.breadcrumb {
        @include container();
        list-style: none;
        padding: 0;
        font-size: 1.07em !important;
        padding: 0.9em 0;
        > li {
          color: white;
          display: inline-block;
          margin-bottom: 0 !important;
          line-height: 1.5em;
          > a {
            color: white !important;
            font-weight: 700 !important;
            &:after {
              content: "/";
              font-weight: 700;
              margin: 0 15px;
            }
            svg {
              fill: white;
              path {
                fill: white;
              }
            }
            &:hover {
              text-decoration: underline !important;
              svg {
                fill: $lightblue;
                path {
                  fill: $lightblue;
                }
              }
            }
          }
          &.active {
            > a {
              font-weight: 400 !important;
            }
          }
          .breadcrumb_sub_nav {
            display: none;
          }
        }
      }
    }
    a {
      color: $textcolor;
    }
    &.desktop {
      display: none;
    }
    &.mobile {
      border-top: 0 solid rgba(78, 85, 91, 0.2);
      position: fixed;
      width: 100%;
      height: 0px;
      background-color: white;
      z-index: 5;
      top: 85px;
      left: 0px;
      overflow: hidden;
      @include transition(all, 0.25s, ease-out, 0s);
      &.open {
        border-top: 1px solid rgba(78, 85, 91, 0.2);
        height: calc(100vh - 85px);
        z-index: z-index("header");
      }
      .stage {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        overflow-y: scroll;
        overflow-x: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
        &.top {
          ul {
            li {
              &:first-child {
                border-top: 0 !important;
              }
              &.search,
              &.language {
                display: none;
              }
              &.contact {
                border-bottom: 0;
                a {
                  background-color: $berry;
                  border-color: $berry;
                  font-weight: 700;
                  font-size: 16px;
                  display: inline-block;
                  width: auto;
                  text-align: center;
                  min-width: 120px;
                  padding: 10px 0px;
                  margin-top: 25px;
                  &:after {
                    display: none;
                  }
                  &:hover {
                    color: $berry;
                    background-color: white;
                  }
                }
              }
            }
          }
        }
        &.transition {
          @include transition(left, 0.15s, ease-out, 0s);
        }
        &.off {
          left: 100%;
        }
        &.exit {
          left: -100%;
        }
        .container {
          @include container();
          a.return {
            font-weight: 700;
            font-size: 20.5px;
            position: relative;
            padding-left: 0;
            width: 100%;
            display: inline-block;
            margin-bottom: 50px;
            margin-top: 25px;
            &:before {
              padding-right: 10px;
              content: url($backarrow);
              position: relative;
              top: -2px;
            }
          }
          h4 {
            font-size: 29px;
            font-weight: 700;
            margin: 0 0 15px 0;
            &:after {
              content: url($doubleblue);
              padding-left: 10px;
              position: relative;
              top: -3px;
            }
            &.l3 {
              font-size: 24px;
            }
          }
          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
              border-bottom: 1px solid rgba(78, 85, 91, 0.2);
              &:first-child {
                border-top: 1px solid rgba(78, 85, 91, 0.2);
              }
              a {
                font-weight: 400;
                font-size: 31.5px;
                display: flex;
                width: 100%;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 23.75px 0;
                opacity: 1;
                &:after {
                  content: url(/packages/health_advances/themes/health_advances/img/arrow.svg);
                  opacity: 1;
                }
                &.active,
                &.nav-selected,
                &.nav-open {
                  opacity: 1;
                  text-decoration: underline;
                  &:after {
                    opacity: 1;
                  }
                }
              }
            }
            &.sub {
              li {
                a {
                  padding: 20px 0;
                  font-size: 20.3px;
                }
                &:last-child {
                  border-bottom: 0px;
                }
              }
              &.l3 {
                li {
                  border-bottom: 0px;
                  a {
                    padding: 10px 0;
                    &:after {
                      display: none;
                    }
                  }
                }
              }
            }
            &.alt {
              margin-bottom: 25px;
              li {
                a {
                  font-weight: 700;
                }
                > ul {
                  display: block;
                  li {
                    padding-left: 35px;
                    border-top: 0 !important;
                    a {
                      font-weight: 400;
                    }
                  }
                }
              }
            }
          }
          &:nth-child(2) {
            h4 {
              display: none;
            }
            ul {
              li {
                border-top: 0 !important;
              }
            }
          }
        }
        &.sectors {
          .container {
            ul {
              li {
                margin-bottom: 0;
                > ul {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    @media only screen and (min-width: 768px) {
      &.mobile {
        top: 105px;
      }
    }
    @media only screen and (min-width: 1024px) {
      &.mobile {
        display: none;
      }
      &.desktop {
        display: block;
        background-color: hsla(0deg, 0%, 100%, 0.97);
        position: fixed;
        top: 137px;
        left: 0;
        width: 100%;
        z-index: 5;
        overflow: hidden;
        height: 0;
        padding-top: 0;
        @include transition(all, 0.25s, ease-out, 0s);
        &.open {
          padding-top: 30px;
          height: calc(100vh - 167px);
        }
        > .contents {
          height: 100%;
          overflow-y: scroll;
          overflow-x: hidden;
        }
        .container {
          @include container();
          display: flex;
          flex-direction: row;
          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
              &:last-child {
                margin-bottom: 0 !important;
              }
              > ul {
                display: none;
              }
              a {
                &.btn {
                  font-size: 16px;
                  min-width: 0px;
                  padding: 10px 0;
                  width: 120px;
                  font-weight: 700 !important;
                  text-align: center;
                  display: block;
                  &:after {
                    display: none;
                  }
                  &:hover {
                    text-decoration: none !important;
                  }
                }
              }
            }
            &.l1 {
              // font-size: 2.6em;
              font-size: 40.5px;
              li {
                // margin-bottom: 0.8em;
                margin-bottom: 40px;
                &.language,
                &.search {
                  margin-bottom: 0px;
                }
                &.contact {
                  margin-bottom: 20px;
                }
                a:not(.btn) {
                  font-weight: 400;
                  opacity: 0.5;
                  color: $textcolor;
                  @include transition(opacity, 0.1s, ease-out, 0s);
                  &:hover {
                    opacity: 1;
                  }
                  &.active,
                  &.nav-selected,
                  &.nav-open {
                    opacity: 1;
                    text-decoration: underline;
                  }
                  &.search,
                  &.language {
                    color: #465057;
                    font-size: 21px;
                    opacity: 1;
                    // font-size: 1.3rem;
                    i,
                    img {
                      margin-left: 5px;
                    }
                    &:hover {
                      text-decoration: underline;
                    }
                  }
                }
              }
            }
          }
          .col {
            width: calc(100% / 3);
            border-right: 1px solid #454b51;
            margin-bottom: 30px;
            &.l3 {
              border-right: 0;
            }
            .contents {
              width: calc(100% - 100px);
              padding: 0 50px;
            }
            &.l2,
            &.l3 {
              display: none;
              h4 {
                font-size: 29px;
                font-weight: 700;
                margin: 0 0 15px 0;
                &:after {
                  content: url($doubleblue);
                  padding-left: 10px;
                  position: relative;
                  top: -3px;
                  left: 0;
                  @include transition(left, 0.1s, ease-out, 0s);
                }
                &:hover {
                  &:after {
                    left: 5px;
                  }
                }
                &.l3 {
                  font-size: 24px;
                }
              }
              &.last {
                h4 {
                  opacity: 0;
                  &:after {
                    display: none;
                  }
                }
              }
              ul {
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                  margin-bottom: 0;
                  border-bottom: 1px solid rgba(78, 85, 91, 0.2);
                  &:first-child {
                    border-top: 1px solid rgba(78, 85, 91, 0.2);
                  }
                  a {
                    font-weight: 400;
                    font-size: 31.5px;
                    display: flex;
                    width: 100%;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding: 23.75px 0;
                    &:after {
                      content: url($onearrow);
                      opacity: 1;
                      @include transition(opacity, 0.1s, ease-out, 0s);
                    }
                  }
                }
                &.nav-open {
                  li {
                    a {
                      opacity: 0.5 !important;
                      &.nav-open {
                        opacity: 1 !important;
                      }
                    }
                  }
                }
                &:hover {
                  li {
                    a {
                      opacity: 0.5 !important;
                      &:hover {
                        opacity: 1 !important;
                      }
                    }
                  }
                }
                &.sub {
                  li {
                    a {
                      padding: 17.25px 0;
                      font-size: 20.3px;
                      opacity: 1;
                      @include transition(opacity, 0.1s, ease-out, 0s);
                      &:hover,
                      &.active,
                      &.nav-selected,
                      &.nav-open {
                        opacity: 1;
                        text-decoration: underline;
                        &:after {
                          opacity: 1;
                        }
                      }
                    }
                    &:last-child {
                      border-bottom: 0px;
                    }
                  }
                  &.l3 {
                    li {
                      border-bottom: 0px;
                      border-top: 0px;
                      a {
                        padding: 10px 0;
                        &:after {
                          display: none;
                        }
                      }
                    }
                    &.alt {
                      margin-bottom: 25px;
                      li {
                        a {
                          font-weight: 700;
                        }
                        > ul {
                          display: block;
                          li {
                            padding-left: 35px;
                            a {
                              font-weight: 400;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            &.active {
              display: block;
            }
          }
        }
      }
    }
  }
}
