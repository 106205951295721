@import "variables";

body#had {
  #main,
  header#header,
  footer#footer,
  nav {
    img {
      height: auto;
      max-width: 100%;
    }
    a {
      cursor: pointer;
      text-decoration: none;
      color: $textcolor;
    }
    h1 {
      font-weight: 300;
      font-size: 3.72em;
    }
    h2 {
      font-weight: 300;
      font-size: 2.6em;
    }
    h3 {
      font-weight: 300;
      font-size: 2.12em;
      line-height: 1.2em;
    }
    h4 {
      font-weight: 400;
      font-size: 1.5em;
    }
    h5 {
      font-weight: 700;
      font-size: 1.25em;
    }
    p {
      font-weight: 400;
      font-size: 1.3em;
      line-height: 1.45em;
      &.md {
        font-size: 1.12em;
      }
      &.sm {
        font-size: 1.02em;
      }
      a {
        &.arrow {
          font-weight: 700;
          font-size: 1.3em;
          // font-size: 1.68rem;
          &:after {
            content: url($bluearrows);
            padding-left: 14px;
            position: relative;
            left: 0;
            @include transition(all, 0.15s, ease-out, 0s);
            // padding-left: 0.75em;
          }
          &:hover,
          &:focus {
            text-decoration: underline;
            &:after {
              left: 5px;
            }
          }
          &.blue {
            color: #057ca3;
            font-weight: 400;
            font-style: italic;
            font-size: 0.9em;
            &:after {
              padding-left: 10px;
            }
          }
        }
      }
    }
    ul,
    ol {
      font-weight: 400;
      font-size: 1.25em;
      li {
        font-size: 1em;
        margin-bottom: 0.56em;
        &:last-child {
          margin-bottom: 0;
        }
        p {
          font-size: 1em;
        }
      }
    }
    hr {
      border: 0;
      outline: 0;
      width: 100%;
      height: 1px;
      border-radius: 0.5px;
      background-color: $textcolor;
      margin: 5em auto;
      &.transparent {
        background-color: rgba(70, 80, 87, 0.2);
      }
      &.dotted {
        height: 2px;
        border-bottom: 2px dotted rgba(79, 85, 92, 0.3);
        background-color: transparent;
      }
      &.arrows {
        background-color: rgba(70, 80, 87, 0.2);
        position: relative;
        overflow: visible;
        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          width: 42px;
          height: 22px;
          background-image: url($arrows);
          background-size: 42px 22px;
          background-repeat: no-repeat;
          background-position: center center;
          padding: 0px 10px;
          background-color: white;
          top: -11px;
        }
      }
    }
    a {
      &.icon-img {
        text-align: center;
        img {
          display: block;
          margin: 0px auto 1.6em;
          transform: scale(0);
          @include transition(transform, 0.5s, ease-out, 0s);
        }
        &.active {
          img {
            transform: scale(1);
          }
        }
        p {
          font-weight: 700;
          color: #3d464c;
          line-height: 1.25em;
        }
        &:hover {
          p {
            text-decoration: underline;
          }
        }
      }
    }
  }
  #main {
    .row {
      img {
        border-radius: 12px;
      }
    }
    a:not(.btn) {
      font-weight: bold;
      color: $lightblue;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
